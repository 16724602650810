// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Portfolio from '../views/Portfolio.vue'
import Error404 from '../views/Error404.vue'

const routes = [
  { path: '/', component: Home, name: 'Home' },
  { path: '/about', component: About, name: 'About' },
  { path: '/portfolio', component: Portfolio, name: 'Portfolio' },
  { path: '/:pathMatch(.*)*', component: Error404, name: 'Not Found' },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to the top of the page when navigating to a new route
    return { top: 0 }
  }
})

export default router

<!-- src/components/home/HeroSection.vue -->
<template>
  <div class="hero-section">
    <!-- Placeholder Image -->
    <img
      class="hero-image"
      src="@/assets/hero-image.jpg"
      alt="Hero Placeholder"
    />
    <!-- Video -->
    <video
      class="hero-video"
      autoplay
      muted
      loop
      playsinline
    >
      <source src="@/assets/hero-video.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <!-- Hero Text -->
    <div class="hero-text" v-if="showHeroText">
      <h1 class="hero-large">
        <span
          v-for="(word, index) in largeTextWords"
          :key="index"
          class="word"
          :style="{ animationDelay: `${index * 0.2}s` }" 
        >
          {{ word }}
        </span>
      </h1>
      <h2 class="hero-small">
        <span
          v-for="(word, index) in smallTextWords"
          :key="index"
          class="word"
          :style="{ animationDelay: `${(largeTextWords.length + index) * 0.2}s` }" 
        >
          {{ word }}
        </span>
      </h2>
    </div>
    <!-- Scrolling Text -->
    <div class="scrolling-text">
      <div class="scrolling-container">
        <span v-for="index in repeatCount" :key="index" class="scrolling-sequence">
          <span v-for="(item, itemIndex) in scrollingItems" :key="itemIndex" class="scrolling-item">
            {{ item }}
            <span class="dot">•</span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';

// Text to be displayed
const largeText = ref('Freelance Designer & Developer');
const smallText = ref('since 2011');

// Split text into words for animation
const largeTextWords = computed(() => {
  const words = largeText.value.split(' ');
  const result = [];
  for (let i = 0; i < words.length; i++) {
    if (words[i] === 'Designer') {
      result.push(`${words[i]} & ${words[i + 2]}`); // Combine "Designer", "&", and "Developer"
      i += 2; // Skip the next two words as they're part of the combined unit
    } else if (words[i] !== '&' && words[i] !== 'Developer') {
      result.push(words[i]);
    }
  }
  return result;
});

const smallTextWords = computed(() => smallText.value.split(' '));

// Control visibility of hero text
const showHeroText = ref(false);

// Scrolling items
const scrollingItems = ref([
  'Business website',
  'eCommerce website',
  'Landing page',
  'Mobile app',
  'UI/UX design',
  'Motion graphic',
  'Company profile',
  'Social media',
  'Web app',
]);

// Duplicate the scrolling sequence to ensure it covers the whole width
const repeatCount = 2; // Adjust this based on the desired coverage

// Show hero text after a delay
onMounted(() => {
  setTimeout(() => {
    showHeroText.value = true; // Show text after 2 seconds
  }, 2000);
});
</script>

<style scoped>
.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* Placeholder Image */
.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

/* Video */
.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2; /* Ensure the video is above the image */
}

/* Hero Text */
.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  z-index: 3;
  pointer-events: none;
  padding: 0 20px; /* Padding for smaller screens */
  max-width: 90%; /* Ensure the text doesn't overflow the container */
  line-height: 1.2; /* Space between lines for readability */
}

.hero-large {
  font-size: 4rem;
  font-weight: bold;
  display: inline-block;
  margin: 0; /* Remove default margin of h1 */
  white-space: normal; /* Allow words to wrap normally */
  word-wrap: break-word; /* Handle wrapping */
}

.hero-small {
  font-size: 1.5rem;
  display: block;
  margin-top: 10px;
  white-space: normal; /* Allow words to wrap normally */
}

.word {
  display: inline-block;
  opacity: 0;
  margin-right: 0.5rem; /* Space between words */
  animation: fadeIn 0.3s forwards; /* Faster fade-in duration */
  animation-timing-function: ease-out;
  white-space: nowrap; /* Prevent breaking within each word */
}

/* Scrolling Text */
.scrolling-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px; /* Adjust height to 50px */
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 4;
}

.scrolling-container {
  white-space: nowrap;
  display: inline-block;
  animation: scroll-left 30s linear infinite; /* Slower scrolling effect */
}

.scrolling-sequence {
  display: inline-block;
}

.scrolling-item {
  color: white;
  font-size: 1.2rem; /* Smaller font size */
  padding: 0 10px; /* Space around each word item */
}

.dot {
  margin: 0 5px 0 20px; /* Balanced spacing around the dot */
  color: white;
  font-size: 1.2rem; /* Match font size to text */
  line-height: 50px; /* Center the dot vertically */
  vertical-align: middle; /* Ensure alignment with text */
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scroll-left {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%); /* Change to half of the width to create loop */
  }
}

/* Media Queries */
@media (max-width: 1024px) { /* Tablet */
  .hero-large {
    font-size: 3rem; /* Adjust for tablet */
  }

  .hero-small {
    font-size: 1.2rem; /* Adjust for tablet */
  }

  .scrolling-item {
    font-size: 1rem; /* Adjust for tablet */
  }

  .dot {
    font-size: 1rem; /* Match font size to text for tablet */
  }
}

@media (max-width: 768px) { /* Mobile */
  .hero-large {
    font-size: 2.5rem; /* Adjust for mobile */
    white-space: normal; /* Allow wrapping */
    word-wrap: break-word; /* Handle wrapping properly */
  }

  .hero-small {
    font-size: 1rem; /* Mobile adjustment */
  }

  .scrolling-item {
    font-size: 0.8rem; /* Smaller font size for mobile */
  }

  .dot {
    font-size: 0.8rem; /* Match font size to text for mobile */
  }
}

@media (max-width: 480px) { /* Small Mobile */
  .hero-large {
    font-size: 1.8rem; /* Adjust for smaller mobile screens */
    white-space: normal; /* Allow wrapping for small screens */
    word-wrap: break-word; /* Handle word wrapping */
  }

  .hero-small {
    font-size: 0.8rem; /* Smaller font for small mobile */
  }

  .scrolling-item {
    font-size: 0.6rem; /* Smaller font for small mobile */
  }

  .dot {
    margin: 0 -5px 0 10px;
    font-size: 0.6rem; /* Match font size to text for small mobile */
  }
}
</style>

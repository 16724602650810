<!-- src/views/Home.vue -->
<template>
  <div>
    <HeroSection />
    <AboutSection />
    <ProjectsSection />
    <ProjectCarousel />
    <Footer />
  </div>
</template>

<script setup>
  import { useHead } from '@vueuse/head';

  useHead({
    title: 'Home | INFINITY-LINK',
    meta: [
      {
        name: 'description',
        content:
          'Welcome to the INFINITY-LINK portfolio coded by Sario, showcasing projects and expertise in web design and development, as well as research contributions.',
      },
      {
        name: 'keywords',
        content:
          'Sario Al Mustafa, INFINITY-LINK, INFINITY, Portfolio, Web Developer, Web Design, Projects',
      },
      // Open Graph tags for social media sharing
      {
        property: 'og:title',
        content: 'Home | INFINITY-LINK',
      },
      {
        property: 'og:description',
        content:
          'Welcome to the INFINITY-LINK portfolio coded by Sario, showcasing projects and expertise in web design and development, as well as research contributions.',
      },
      {
        property: 'og:image',
        content: 'https://infinity-link.com/images/InfinityLink-cover.JPG',
      },
      {
        property: 'og:url',
        content: 'https://infinity-link.com/',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      // Twitter Card tags
      {
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        name: 'twitter:title',
        content: 'Home | INFINITY-LINK',
      },
      {
        name: 'twitter:description',
        content:
          'Welcome to the INFINITY-LINK portfolio coded by Sario, showcasing projects and expertise in web design and development, as well as research contributions.',
      },
      {
        name: 'twitter:image',
        content: 'https://infinity-link.com/images/InfinityLink-cover.JPG',
      },
    ],
  });

  import HeroSection from '../components/home/HeroSection.vue';
  import AboutSection from '../components/home/AboutSection.vue';
  import ProjectsSection from '../components/home/ProjectsSection.vue';
  import ProjectCarousel from '../components/home/ProjectsCarousel.vue';
  import Footer from '../components/Footer.vue';
</script>

<style scoped>
  /* Home styles */
</style>

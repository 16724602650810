// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { createHead } from '@vueuse/head';

gsap.registerPlugin(ScrollTrigger);

const app = createApp(App);
const head = createHead();

app.use(store);
app.use(router);
app.use(head);
app.mount('#app');

<template>
  <div class="error-page">
    <div class="content">
      <h1>404</h1>
      <p>Oops! The page you're looking for doesn't exist.</p>
      <router-link to="/" class="home-button">
        <button
          class="error-button"
          @mousemove="handleMouseMove"
          @mouseleave="handleMouseLeave"
          ref="homeButton"
        >
          <span class="button-text">Go to Home</span>
        </button>
      </router-link>
    </div>
  </div>
</template>

<script setup>
  import { useHead } from '@vueuse/head';

  useHead({
    title: '404 | INFINITY-LINK',
    meta: [
      {
        name: 'description',
        content:
          'Welcome to the INFINITY-LINK portfolio coded by Sario, showcasing projects and expertise in web design and development, as well as research contributions.',
      },
      {
        name: 'keywords',
        content:
          'Sario Al Mustafa, INFINITY-LINK, INFINITY, Portfolio, Web Developer, Web Design, Projects',
      },
      // Open Graph tags for social media sharing
      {
        property: 'og:title',
        content: '404 | INFINITY-LINK',
      },
      {
        property: 'og:description',
        content:
          'Welcome to the INFINITY-LINK portfolio coded by Sario, showcasing projects and expertise in web design and development, as well as research contributions.',
      },
      {
        property: 'og:image',
        content: 'https://infinity-link.com/images/InfinityLink-cover.JPG',
      },
      {
        property: 'og:url',
        content: 'https://infinity-link.com/',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      // Twitter Card tags
      {
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        name: 'twitter:title',
        content: '404 | INFINITY-LINK',
      },
      {
        name: 'twitter:description',
        content:
          'Welcome to the INFINITY-LINK portfolio coded by Sario, showcasing projects and expertise in web design and development, as well as research contributions.',
      },
      {
        name: 'twitter:image',
        content: 'https://infinity-link.com/images/InfinityLink-cover.JPG',
      },
    ],
  });

import { ref } from 'vue'

const homeButton = ref(null)

const handleMouseMove = (event) => {
  const buttonElement = homeButton.value
  const bounding = buttonElement.getBoundingClientRect()
  const centerX = bounding.left + bounding.width / 2
  const centerY = bounding.top + bounding.height / 2
  const deltaX = event.clientX - centerX
  const deltaY = event.clientY - centerY
  const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2)

  if (distance < 150) {
    const strength = 20 / distance
    buttonElement.style.transform = `translate(${deltaX * strength}px, ${deltaY * strength}px)`
  } else {
    buttonElement.style.transform = 'translate(0, 0)'
  }
}

const handleMouseLeave = () => {
  const buttonElement = homeButton.value
  buttonElement.style.transform = 'translate(0, 0)'
}
</script>

<style scoped>
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #2e2e2e;
  color: white;
  text-align: center;
  padding: 0 20px; /* Add padding for mobile */
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 6rem;
  margin: 0;
  font-weight: 700;
}

p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.home-button {
  text-decoration: none; /* Ensure no underline on the router-link */
}

.error-button {
  position: relative;
  display: block;
  padding: 20px 50px;
  font-size: 1.2rem;
  color: white;
  background-color: #2e2e2e;
  border: 1px solid white;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.6s ease-out, background-color 0.4s ease-out, border-color 0.4s ease;
}

.error-button::before {
  content: '';
  position: absolute;
  bottom: -50%;
  left: 50%;
  width: 150%;
  height: 175%;
  background-color: #50c878;
  border-radius: 50%;
  transform: translate(-50%, 100%) scale(1, 0.3);
  transition: transform 0.4s ease-out;
  z-index: 0;
}

.error-button:hover::before {
  transform: translate(-50%, 0) scale(1, 1);
}

.error-button:hover {
  border-color: #50c878;
}

.error-button:hover .button-text {
  color: white;
}

.button-text {
  position: relative;
  z-index: 1;
  color: white;
  text-decoration: none; /* Remove underline from button text */
}

/* Responsive styles */

/* Tablet */
@media (max-width: 768px) {
  h1 {
    font-size: 4.5rem; /* Smaller font size for tablets */
  }

  p {
    font-size: 1.2rem; /* Reduce the paragraph size */
  }

  .error-button {
    padding: 15px 40px; /* Adjust button size */
    font-size: 1.1rem;
  }
}

/* Mobile */
@media (max-width: 480px) {
  h1 {
    font-size: 3rem; /* Even smaller font size for mobile */
  }

  p {
    font-size: 1rem; /* Adjust paragraph size for mobile */
    margin-bottom: 20px;
  }

  .error-button {
    padding: 12px 30px; /* Smaller padding for mobile */
    font-size: 1rem; /* Smaller font size for button text */
  }
}
</style>

<!-- src/components/Footer.vue -->
<template>
  <footer class="footer" ref="footer">
    <div class="footer-content">
      <div class="footer-left">
        <img src="/images/ProfileImage.jpg" alt="Profile Image" class="profile-image" />
        <h2 class="footer-heading">Let’s work together</h2>
      </div>
      <div class="footer-right">
        <div class="horizontal-line">
          <a href="mailto:info@infinity-link.com" class="circle-link">
            <div
              class="magnetic-circle"
              @mousemove="handleMouseMove"
              @mouseleave="handleMouseLeave"
              ref="circle"
            >
              <span class="circle-text">Get in touch</span>
            </div>
          </a>
        </div>
        <a href="mailto:info@infinity-link.com" class="email-button-link">
          <div
            class="email-button"
            @mousemove="handleMouseMove"
            @mouseleave="handleMouseLeave"
            ref="emailButton"
          >
            <span class="button-text">info@infinity-link.com</span>
          </div>
        </a>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="local-time-section">
        <div class="label">LOCAL TIME</div>
        <div class="local-time">
          {{ localTime }}
        </div>
      </div>
      <div class="social-links-section">
        <div class="label">SOCIAL</div>
        <div class="social-links">
          <a
            href="https://www.instagram.com/sario.al.mustafa/"
            target="_blank"
            rel="noopener noreferrer"
            @mousemove="handleMouseMoveSocial"
            @mouseleave="handleMouseLeaveSocial"
          >
            Instagram
          </a>
          <a
            href="https://x.com/sarioalmustafa"
            target="_blank"
            rel="noopener noreferrer"
            @mousemove="handleMouseMoveSocial"
            @mouseleave="handleMouseLeaveSocial"
          >
            X.com
          </a>
          <a
            href="https://linkedin.com/in/sario-al-mustafa"
            target="_blank"
            rel="noopener noreferrer"
            @mousemove="handleMouseMoveSocial"
            @mouseleave="handleMouseLeaveSocial"
          >
            LinkedIn
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

const circle = ref(null)
const emailButton = ref(null)
const footer = ref(null)

const handleMouseMove = (event) => {
  const element = event.currentTarget
  const bounding = element.getBoundingClientRect()
  const centerX = bounding.left + bounding.width / 2
  const centerY = bounding.top + bounding.height / 2
  const deltaX = event.clientX - centerX
  const deltaY = event.clientY - centerY
  const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2)

  if (distance < 150) {
    const strength = 20 / distance
    element.style.transform = `translate(${deltaX * strength}px, ${deltaY * strength}px)`
  } else {
    element.style.transform = 'translate(0, 0)'
  }
}

const handleMouseLeave = (event) => {
  const element = event.currentTarget
  element.style.transform = 'translate(0, 0)'
}

const handleMouseMoveSocial = (event) => {
  const link = event.target
  const rect = link.getBoundingClientRect()
  const offsetX = event.clientX - rect.left - rect.width / 2
  const offsetY = event.clientY - rect.top - rect.height / 2
  const maxOffset = 10

  link.style.transform = `translate(${Math.min(
    Math.max(offsetX, -maxOffset),
    maxOffset
  )}px, ${Math.min(Math.max(offsetY, -maxOffset), maxOffset)}px)`
}

const handleMouseLeaveSocial = (event) => {
  const link = event.target
  link.style.transform = 'translate(0, 0)'
}

const localTime = ref('')

const updateTime = () => {
  const now = new Date()
  localTime.value = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' })
}

const handleScroll = () => {
  const footerElement = footer.value
  const scrollPosition = window.scrollY + window.innerHeight
  const footerTop = footerElement.offsetTop

  if (scrollPosition > footerTop) {
    const progress = (scrollPosition - footerTop) / footerElement.clientHeight
    const radius = Math.max(0, 25 - progress * 25)
    const bottomCurve = Math.max(0, 12.5 - progress * 12.5)
    footerElement.style.borderTopLeftRadius = `${radius}% ${bottomCurve}vw`
    footerElement.style.borderTopRightRadius = `${radius}% ${bottomCurve}vw`
  } else {
    footerElement.style.borderTopLeftRadius = '25% 12.5vw'
    footerElement.style.borderTopRightRadius = '25% 12.5vw'
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  updateTime()
  setInterval(updateTime, 60000) // Update time every minute
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style scoped>
.footer {
  background-color: #333333;
  color: white;
  padding: 80px 20px 50px 20px;
  border-top-left-radius: 25% 12.5vw;
  border-top-right-radius: 25% 12.5vw;
  transition: border-radius 0.4s ease-out;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-left {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
}

.footer-heading {
  font-size: 5rem;
  font-weight: 400;
  margin: 0;
  color: white;
  white-space: nowrap; /* Prevent the text from breaking into two lines */
}

.footer-right {
  width: 100%;
  margin-top: 20px;
}

.horizontal-line {
  width: 100%;
  height: 0.5px;
  background-color: white;
  position: relative;
  margin-top: 20px;
}

.circle-link {
  position: absolute;
  right: 40px;
  top: -100px;
  text-decoration: none;
}

.magnetic-circle {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: transform 0.6s ease-out, background-color 0.4s ease-out;
  cursor: pointer;
}

.circle-text {
  z-index: 1;
  font-size: 1.5rem;
  color: #333333;
  position: relative;
  transition: color 0.4s ease-out;
}

.magnetic-circle::before {
  content: '';
  position: absolute;
  bottom: -50%;
  left: 50%;
  width: 150%;
  height: 150%;
  background-color: #50c878;
  border-radius: 50%;
  transform: translate(-50%, 100%) scale(1, 0.3);
  transition: transform 0.4s ease-out;
  z-index: 0;
}

.magnetic-circle:hover::before {
  transform: translate(-50%, 0) scale(1, 1);
}

.magnetic-circle:hover .circle-text {
  color: white;
}

.email-button-link {
  display: block;
  text-decoration: none;
  width: max-content;
  margin-top: 40px;
}

.email-button {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 20px 50px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #333333;
  border: 1px solid white;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.6s ease-out, background-color 0.4s ease-out, border-color 0.4s ease;
}

.email-button::before {
  content: '';
  position: absolute;
  bottom: -50%;
  left: 50%;
  width: 150%;
  height: 175%;
  background-color: #50c878;
  border-radius: 50%;
  transform: translate(-50%, 100%) scale(1, 0.3);
  transition: transform 0.4s ease-out;
  z-index: 0;
}

.email-button:hover::before {
  transform: translate(-50%, 0) scale(1, 1);
}

.email-button:hover {
  border-color: #50c878;
}

.email-button:hover .button-text {
  color: white;
}

.button-text {
  position: relative;
  z-index: 1;
  color: white;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 150px;
  font-size: 1rem;
}

.local-time-section,
.social-links-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
}

.label {
  font-size: 0.75rem;
  font-weight: 600;
  color: #a0a0a0;
  margin-bottom: 20px;
}

.local-time {
  font-weight: 400;
}

.social-links {
  display: flex;
  gap: 30px;
}

.social-links a {
  color: white;
  text-decoration: none;
  position: relative;
  transition: transform 0.5s ease-out;
}

.social-links a::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%) scale(0);
  transition: transform 0.3s ease;
}

.social-links a:hover::after {
  transform: translateX(-50%) scale(1);
}

/* Media Queries for before Tablet */
@media (max-width: 1024px) {
  .footer-heading {
    font-size: 4rem;
  }
  .magnetic-circle {
    width: 185px; /* Smaller size for mobile */
    height: 185px;
  }
}

/* Media Queries for just before Tablet */
@media (max-width: 864px) {
  .footer-heading {
    font-size: 3.4rem;
  }

  .magnetic-circle {
    width: 170px; /* Smaller size for mobile */
    height: 170px;
  }

  .email-button {
    padding: 15px 35px;
  }
}

/* Media Queries for Tablet */
@media (max-width: 768px) {
  .footer-content {
    align-items: center;
  }

  .footer-left {
    flex-direction: row;
    align-items: center;
    text-align: left;
    justify-content: flex-start;
    width: 100%;
  }

  .footer-heading {
    font-size: 2.8rem; /* Slightly smaller font size */
    white-space: nowrap; /* Prevent text wrapping */
  }

  .profile-image {
    margin-bottom: 0;
    margin-right: 15px;
  }

  .footer-right {
    margin-top: 40px;
    width: 100%;
  }

  .horizontal-line {
    margin-top: 20px;
    position: relative;
  }

  .circle-link {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-right: 10px;
  }

  .magnetic-circle {
    width: 150px;
    height: 150px;
  }

  .email-button-link {
    margin-top: 60px;
  }

  .footer-bottom {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-top: 80px;
  }

  .local-time-section,
  .social-links-section {
    padding: 0;
    margin-bottom: 0;
  }

  .social-links {
    justify-content: center;
    gap: 20px;
  }
}

/* Media Queries for Mobile */
@media (max-width: 480px) {
  .footer-heading {
    font-size: 2.5rem;
    white-space: normal; /* Allow the text to wrap into two lines */
  }

  .profile-image {
    margin-bottom: 10px;
  }

  .horizontal-line {
    margin-top: 20px;
    position: relative;
  }

  .circle-link {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-right: 10px;
  }

  .magnetic-circle {
    width: 120px; /* Smaller size for mobile */
    height: 120px;
  }

  .circle-text {
    font-size: 0.9rem; /* Smaller text size for mobile */
  }

  .email-button-link {
    margin-top: 40px;
  }

  .email-button {
    padding: 12px 20px;
    font-size: 0.85rem;
  }

  .footer-bottom {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
  }

  .footer {
    padding: 60px 15px 40px 15px;
    border-top-left-radius: 15% 7.5vw;
    border-top-right-radius: 15% 7.5vw;
  }

  .local-time-section,
  .social-links-section {
    margin-bottom: 0;
    font-size: 0.75rem;
  }

  .social-links {
    gap: 12px; /* Reduce the gap between social links */
  }
}

</style>

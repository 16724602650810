<!-- src/views/About.vue -->
<template>
  <div>
    <AboutHeading />
    <AboutMeSection />
    <AboutProcess />
    <AboutAchievements />
    <Footer />
  </div>
</template>
  
<script setup>
  import { useHead } from '@vueuse/head';

  useHead({
    title: 'About | INFINITY-LINK',
    meta: [
      {
        name: 'description',
        content:
          'Welcome to the INFINITY-LINK portfolio coded by Sario, showcasing projects and expertise in web design and development, as well as research contributions.',
      },
      {
        name: 'keywords',
        content:
          'Sario Al Mustafa, INFINITY-LINK, INFINITY, Portfolio, Web Developer, Web Design, Projects',
      },
      // Open Graph tags for social media sharing
      {
        property: 'og:title',
        content: 'About | INFINITY-LINK',
      },
      {
        property: 'og:description',
        content:
          'Welcome to the INFINITY-LINK portfolio coded by Sario, showcasing projects and expertise in web design and development, as well as research contributions.',
      },
      {
        property: 'og:image',
        content: 'https://infinity-link.com/images/InfinityLink-cover.JPG',
      },
      {
        property: 'og:url',
        content: 'https://infinity-link.com/',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      // Twitter Card tags
      {
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        name: 'twitter:title',
        content: 'About | INFINITY-LINK',
      },
      {
        name: 'twitter:description',
        content:
          'Welcome to the INFINITY-LINK portfolio coded by Sario, showcasing projects and expertise in web design and development, as well as research contributions.',
      },
      {
        name: 'twitter:image',
        content: 'https://infinity-link.com/images/InfinityLink-cover.JPG',
      },
    ],
  });

  import AboutHeading from '../components/about/AboutHeading.vue'
  import AboutMeSection from '../components/about/AboutMeSection.vue'
  import AboutProcess from '../components/about/AboutProcess.vue'
  import AboutAchievements from '../components/about/AboutAchievements.vue'
  import Footer from '../components/Footer.vue'
</script>

<style scoped>
  /* About styles */
</style>

<!-- src/components/home/ProjectsCarousel.vue -->
<template>
  <div class="carousel-section" ref="carouselSection">
    <div
      class="image-row row-1"
      :style="{ transform: `translateX(${row1Offset}px)` }"
    >
      <div
        v-for="(project, index) in row1Projects"
        :key="`row-1-${index}`"
        class="project-image"
      >
        <img :src="project.image" alt="" />
      </div>
    </div>
    <div
      class="image-row row-2"
      :style="{ transform: `translateX(${row2Offset}px)` }"
    >
      <div
        v-for="(project, index) in row2Projects"
        :key="`row-2-${index}`"
        class="project-image"
      >
        <img :src="project.image" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

const projects = ref([
  { image: '/images/Fokalizator.jpg' },
  { image: '/images/Podgorica.jpg' },
  { image: '/images/GoogleMapsTransit.jpg' },
  { image: '/images/PodgoricaTravel.jpg' },
  { image: '/images/ZlataraMethadzovic.jpg' },
  { image: '/images/InvestInPodgorica.jpg' },
  { image: '/images/Covidoscope.jpg' },
  { image: '/images/SkupstinaPodgorice.jpg' },
])

// Split the projects array into two distinct groups for row 1 and row 2
const middleIndex = Math.ceil(projects.value.length / 2)
const row1Projects = ref(projects.value.slice(0, middleIndex))
const row2Projects = ref(projects.value.slice(middleIndex))

const row1Offset = ref(-5 * window.innerWidth / 100) // Initial offset to cut off the first image
const row2Offset = ref(5 * window.innerWidth / 100) // Initial offset to cut off the last image

const handleScroll = () => {
  const scrollPosition = window.scrollY
  const windowHeight = window.innerHeight
  const carouselSection = document.querySelector('.carousel-section')
  const sectionTop = carouselSection.getBoundingClientRect().top + scrollPosition

  if (scrollPosition + windowHeight > sectionTop && scrollPosition < sectionTop + carouselSection.offsetHeight) {
    const progress = (scrollPosition + windowHeight - sectionTop) / windowHeight

    // Move the first row left to right
    row1Offset.value = -5 * window.innerWidth / 100 + (progress * 5 * window.innerWidth / 100)
    
    // Move the second row right to left
    row2Offset.value = 5 * window.innerWidth / 100 - (progress * 5 * window.innerWidth / 100)
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style scoped>
.carousel-section {
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
  gap: 1.2vw;
  padding: 0 0 5% 0;
}

.image-row {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 10vw);
  margin-left: -5vw; /* Initial shift to cut off the first image */
  transition: transform 0.3s ease-out;
}

.project-image {
  flex-shrink: 0;
  width: 26.5vw; /* Slightly increased width */
  margin-right: 20px; /* Space between images */
  border: 40px solid #ebebeb; /* Add border with color #d4d4d4 */
  box-sizing: border-box; /* Include border in the element's dimensions */
}

.project-image img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.row-1 {
  transform: translateX(-5vw); /* Initial transform to cut off the first image */
}

.row-2 {
  transform: translateX(5vw); /* Initial transform to cut off the last image */
}

/* Responsive styling */
@media (max-width: 1024px) {
  /* Tablet view */
  .project-image {
    width: 35vw; /* Adjust width for tablet */
    margin-right: 15px;
    border: 30px solid #ebebeb; /* Adjust border size for tablet */
  }
}

@media (max-width: 768px) {
  /* Small tablet and large mobile view */
  .project-image {
    width: 45vw; /* Adjust width for smaller screens */
    margin-right: 10px;
    border: 20px solid #ebebeb; /* Adjust border size for smaller screens */
  }
}

@media (max-width: 480px) {
  /* Mobile view */
  .project-image {
    width: 60vw; /* Adjust width for mobile */
    margin-right: 5px;
    border: 10px solid #ebebeb; /* Adjust border size for mobile */
  }
}
</style>

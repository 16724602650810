<!-- src/components/PagePreloader.vue -->
<template>
  <div v-if="showPreloader" class="preloader" :class="{ 'slide-up': slideUp }">
    <div class="preloader-text">
      <span class="dot"></span>
      <span class="text">{{ currentPage }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'

const showPreloader = ref(false)
const slideUp = ref(false)
const currentPage = ref('')

const route = useRoute()

const disableScroll = () => {
  document.body.style.overflow = 'hidden'
}

const enableScroll = () => {
  document.body.style.overflow = ''
}

const startLoading = () => {
  disableScroll()
  showPreloader.value = true
  setTimeout(() => {
    slideUp.value = true
    setTimeout(() => {
      showPreloader.value = false
      slideUp.value = false
      enableScroll()
    }, 1000) // Wait for the slide-up animation to complete
  }, 1000) // Show page name for 1 second before sliding up
}

watch(route, (to) => {
  if (to.name) {
    currentPage.value = to.name
    startLoading()
  }
})

onMounted(() => {
  if (route.name) {
    currentPage.value = route.name
    startLoading()
  }
})

onUnmounted(enableScroll)
</script>

<style scoped>
.preloader {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 9999;
  clip-path: ellipse(100% 100% at 50% 50%);
  transition: clip-path 1s ease;
}

.preloader-text {
  display: flex;
  align-items: center;
  color: white;
  font-size: 4rem;
  text-align: center;
  position: relative;
  transition: transform 1s ease;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  margin-right: 20px;
  display: inline-block;
}

.slide-up {
  clip-path: ellipse(100% 0% at 50% 0%);
}

.slide-up .preloader-text {
  transform: translateY(-100vh);
}

@media (max-width: 768px) {
  .preloader-text {
    font-size: 3.2rem;
  }
}
</style>

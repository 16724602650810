<!-- src/components/about/AboutHeading.vue -->
 <template>
  <div class="about-header">
    <div class="header-content">
      <h1 class="header-title">
        Helping brands thrive in the digital world
      </h1>
      <div class="header-divider">
        <hr class="divider-line" />
        <div 
          class="circle-icon" 
          @mousemove="handleMouseMove"
          @mouseleave="handleMouseLeave"
          ref="circleIcon"
        >
          <!-- GIF of the internet globe inside the circle -->
          <img src="/Globe.gif" alt="Internet Globe" class="globe-gif" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const circleIcon = ref(null);

const handleMouseMove = (event) => {
  const circleElement = circleIcon.value;
  const bounding = circleElement.getBoundingClientRect();
  const centerX = bounding.left + bounding.width / 2;
  const centerY = bounding.top + bounding.height / 2;
  const deltaX = event.clientX - centerX;
  const deltaY = event.clientY - centerY;
  const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);

  if (distance < 200) {
    const strength = 8 / distance;
    circleElement.style.transform = `translate(${deltaX * strength}px, ${deltaY * strength}px)`;
  } else {
    circleElement.style.transform = 'translate(0, 0)';
  }
};

const handleMouseLeave = () => {
  const circleElement = circleIcon.value;
  circleElement.style.transform = 'translate(0, 0)';
};
</script>

<style scoped>
.about-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px; /* Set a fixed height */
  padding: 0 20px;
}

.header-content {
  text-align: left; /* Align text to the left */
  max-width: 1000px; /* Limit the width of the text block */
  width: 100%;
  position: relative;
}

.header-title {
  font-size: 5rem; /* Very large font size */
  font-weight: 400;
  color: #333333;
  line-height: 1.2;
  margin-bottom: 80px; /* Space between the text and the divider */
}

.header-divider {
  display: flex;
  align-items: center;
  position: relative;
}

.divider-line {
  width: 100%;
  border: none;
  border-top: 2px solid #c8c8c8; /* Similar color to other horizontal lines */
  margin: 0;
}

.circle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 50px; /* Default position */
  width: 200px; /* Large circle */
  height: 200px;
  background-color: #333333; /* Dark color for the circle */
  border-radius: 50%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1; /* Ensure the circle appears above the line */
  transition: transform 0.15s ease; /* Smooth transition for hover effect */
  overflow: hidden; /* Ensure the ::before element is contained */
}

.circle-icon::before {
  content: '';
  position: absolute;
  bottom: -100%; /* Start completely below the circle */
  left: 50%;
  width: 200%; /* Cover the circle */
  height: 200%; /* Ensure it covers the whole circle */
  background-color: #50c878; /* Hover color */
  border-radius: 50%;
  transform: translateX(-50%) translateY(100%) scale(1, 0.5); /* Initial position */
  transition: transform 0.4s ease-out; /* Make the animation start smoothly */
  z-index: 0;
}

.circle-icon:hover::before {
  transform: translateX(-50%) translateY(0) scale(1, 1); /* Animate to cover the circle from bottom to top */
}

.circle-icon:hover .globe-gif {
  color: white; /* Keep text white on hover */
}

.globe-gif {
  width: 60%; /* Adjust size of the GIF inside the circle */
  height: auto;
  border-radius: 50%; /* Make sure the GIF is circular inside the circle icon */
  position: relative; /* Ensure it stays above the ::before */
  z-index: 1;
}

/* Responsive styles */

/* Tablet */
@media (max-width: 768px) {
  .about-header {
    height: auto; /* Let height adjust naturally */
    padding: 60px 15px 40px 15px; /* Add top padding and padding to bottom */
  }

  .header-content {
    text-align: left; /* Center the text */
  }

  .header-title {
    font-size: 3.5rem; /* Adjust font size for tablets */
    margin-bottom: 60px; /* Reduce space */
  }

  .circle-icon {
    width: 150px; /* Adjust circle size for tablets */
    height: 150px;
    right: 12px; /* Align with edge of the container */
  }

  .globe-gif {
    width: 70%; /* Adjust GIF size for tablets */
  }
}

/* Mobile */
@media (max-width: 480px) {
  .about-header {
    height: auto; /* Let height adjust naturally */
    padding: 70px 10px 30px 10px; /* Add padding to the top, bottom, and sides */
  }

  .header-content {
    text-align: left; /* Center text on mobile */
  }

  .header-title {
    font-size: 2.5rem; /* Smaller font size on mobile */
    margin-bottom: 50px; /* Adjust space */
  }

  .circle-icon {
    width: 120px; /* Adjust circle size for mobile */
    height: 120px;
    right: 12px; /* Move circle further left */
  }

  .globe-gif {
    width: 60%; /* Adjust GIF size for mobile */
  }
}
</style>

<!-- src/components/Preloader.vue -->
 <template>
  <div v-if="showPreloader" class="preloader" :class="{ 'slide-up': slideUp }">
    <div class="preloader-text" v-if="showText">
      <span class="dot"></span>
      <span class="text">{{ currentWord }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineEmits } from 'vue'

const emit = defineEmits(['finished'])

const words = [
  'Hello',
  'مرحبا',  // Arabic
  'Hallo',  // German
  'こんにちは',  // Japanese
  'Hola',   // Spanish
  'Zdravo'  // Montenegrin
]

const showPreloader = ref(true)
const showText = ref(false)
const slideUp = ref(false)
const currentWord = ref(words[0])
let wordIndex = 0

const changeWord = () => {
  currentWord.value = words[wordIndex]
  wordIndex = (wordIndex + 1) % words.length
}

const disableScroll = () => {
  document.body.style.overflow = 'hidden'
}

const enableScroll = () => {
  document.body.style.overflow = ''
}

const startLoading = () => {
  disableScroll()
  setTimeout(() => {
    showText.value = true
    const interval = setInterval(changeWord, 200)  // Change word every 200ms
    setTimeout(() => {
      clearInterval(interval)
      setTimeout(() => {
        slideUp.value = true
        setTimeout(() => {
          showPreloader.value = false
          enableScroll()
          emit('finished')
        }, 800)  // Wait for the slide-up animation to complete
      }, 500)  // Show last word for 0.5 second before sliding up
    }, 1600)  // Show words for 1.6 seconds
  }, 100)
}

onMounted(startLoading)
onUnmounted(enableScroll)
</script>

<style scoped>
.preloader {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 9999;
  clip-path: ellipse(100% 100% at 50% 50%);
  transition: clip-path 0.8s ease;
}

.preloader-text {
  display: flex;
  align-items: center;
  color: white;
  font-size: 4rem;
  text-align: center;
  transition: transform 0.8s ease;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  margin-right: 20px;
  display: inline-block;
}

.slide-up {
  clip-path: ellipse(100% 0% at 50% 0%);
}

.slide-up .preloader-text {
    transform: translateY(-100vh);
  }

@media (max-width: 768px) {
  .preloader-text {
    font-size: 3.2rem;
  }
}
</style>

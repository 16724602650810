<template>
  <div class="projects-section">
    <h2 class="projects-title">PROJECTS</h2>
    <hr class="projects-line" />

    <!-- Main section wrapping all projects -->
    <div 
      class="main-projects-section"
      @mouseenter="showPopup"
      @mouseleave="hidePopup"
    >
      <!-- Conditionally render router-link or a tag -->
      <div 
        v-for="(project, index) in projects"
        :key="index"
      >
        <router-link
          v-if="!isExternalLink(project.link)"
          :to="project.link"
          class="project-link"
        >
          <div
            class="project"
            @mouseenter="handleMouseEnter(index, $event)"
            @mousemove="movePopup"
          >
            <div class="project-info">
              <h3 class="project-name">{{ project.name }}</h3>
              <p class="project-category">{{ project.category }}</p>
            </div>
            <hr class="project-divider" v-if="index < projects.length - 1" />
          </div>
        </router-link>

        <a
          v-else
          :href="project.link"
          class="project-link"
          target="_blank" 
          rel="noopener"
        >
          <div
            class="project"
            @mouseenter="handleMouseEnter(index, $event)"
            @mousemove="movePopup"
          >
            <div class="project-info">
              <h3 class="project-name">{{ project.name }}</h3>
              <p class="project-category">{{ project.category }}</p>
            </div>
            <hr class="project-divider" v-if="index < projects.length - 1" />
          </div>
        </a>
      </div>

      <!-- Popup box that appears on hover -->
      <transition name="fade-zoom">
        <div
          v-if="popupVisible && popupIndex !== null"
          class="project-popup"
          :style="{ 
            top: `${popupPosition.y}px`, 
            left: `${popupPosition.x}px`,
            transformOrigin: 'center center',
            transform: `translate(-50%, -50%) scale(${popupScale})`,
            height: `${popupHeight}px`
          }"
        >
          <div class="image-wrapper">
            <div 
              class="image-container" 
              :style="{ transform: `translateY(-${popupIndex * 100}%)` }"
            >
              <img 
                v-for="(project, index) in projects"
                :key="index"
                :src="project.image" 
                alt="Project Image" 
                class="project-image"
                @load="onImageLoad"
              />
            </div>
          </div>
          <!-- Handle View button for both external and internal links -->
          <router-link v-if="!isExternalLink(projects[popupIndex].link)" :to="projects[popupIndex].link" class="view-button">
            <span class="view-button-text">View</span>
          </router-link>
          <a v-else :href="projects[popupIndex].link" target="_blank" rel="noopener" class="view-button">
            <span class="view-button-text">View</span>
          </a>
        </div>
      </transition>
    </div>

    <!-- Add line and button after the last project -->
    <hr class="projects-end-line" />
    <router-link to="/portfolio" class="more-projects-button-link">
      <button
        class="more-projects-button"
        @mousemove="handleButtonMouseMove"
        @mouseleave="handleButtonMouseLeave"
        ref="moreProjectsButton"
      >
        <span class="button-text">More Projects</span>
      </button>
    </router-link>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { debounce } from 'lodash'

// Define your projects array
const projects = ref([
  { name: 'Fokalizator', category: 'Design & Development', image: '/images/Fokalizator.jpg', link: 'https://fokalizator.me/' },
  { name: 'Podgorica', category: 'Design & Development', image: '/images/Podgorica.jpg', link: 'https://podgorica.me/' },
  { name: 'Google Maps Transit', category: 'Development', image: '/images/GoogleMapsTransit.jpg', link: 'https://www.google.com/maps/search/Transit+stations/@42.4476235,19.2455,14z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MDkwOC4wIKXMDSoASAFQAw%3D%3D' },
  { name: 'Podgorica Travel', category: 'Design & Development', image: '/images/PodgoricaTravel.jpg', link: 'https://podgorica.travel/' }
])

const popupVisible = ref(false)
const popupIndex = ref(null)
const popupPosition = ref({ x: 0, y: 0 })
const popupScale = ref(0)
const popupHeight = ref(200) // Set a default height for the popup
let animationFrame = null

const showPopup = () => {
  popupVisible.value = true
  setTimeout(() => {
    popupScale.value = 1 // Trigger grow-in effect when hovering over main section
  }, 0)
}

const hidePopup = debounce(() => {
  popupScale.value = 0 // Shrink the popup immediately
  setTimeout(() => {
    popupVisible.value = false
    popupIndex.value = null
  }, 150) // Match the faster transition duration
}, 50)

const handleMouseEnter = (index, event) => {
  if (popupIndex.value !== index) {
    popupIndex.value = index
    updatePopupHeight()
  }
  movePopup(event)
}

const movePopup = (event) => {
  if (animationFrame) cancelAnimationFrame(animationFrame)
  animationFrame = requestAnimationFrame(() => {
    popupPosition.value = {
      x: event.clientX,
      y: event.clientY
    }
  })
}

const onImageLoad = (event) => {
  popupHeight.value = event.target.clientHeight || 200
}

onMounted(() => {
  updatePopupHeight()
})

const updatePopupHeight = () => {
  const imgElement = document.querySelector('.project-image')
  if (imgElement) {
    popupHeight.value = imgElement.clientHeight || 200
  }
}

// Check if the link is external
const isExternalLink = (link) => {
  return link.startsWith('http://') || link.startsWith('https://')
}

const moreProjectsButton = ref(null)

const handleButtonMouseMove = (event) => {
  const buttonElement = moreProjectsButton.value
  const bounding = buttonElement.getBoundingClientRect()
  const centerX = bounding.left + bounding.width / 2
  const centerY = bounding.top + bounding.height / 2
  const deltaX = event.clientX - centerX
  const deltaY = event.clientY - centerY
  const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2)

  if (distance < 200) {
    const strength = 6 / distance
    buttonElement.style.transform = `translate(${deltaX * strength}px, ${deltaY * strength}px)`
  } else {
    buttonElement.style.transform = 'translate(0, 0)'
  }
}

const handleButtonMouseLeave = () => {
  const buttonElement = moreProjectsButton.value
  buttonElement.style.transform = 'translate(0, 0)'
}
</script>

<style scoped>
.projects-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 5% 20px 65px 20px;
  color: #333333;
}

.projects-title {
  font-size: 0.8rem;
  color: #a0a0a0;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  text-align: left;
  font-weight: normal;
}

.projects-line {
  border: none;
  border-top: 1px solid #c8c8c8;
  margin: 0;
}

.main-projects-section {
  position: relative;
}

.project-link {
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Ensure text inherits the color */
}

.project {
  margin: 0;
  padding: 0;
  position: relative;
}

.project-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 5%;
  margin: 0;
}

.project-name,
.project-category {
  transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition for movement and color change */
}

.project:hover .project-name {
  transform: translateX(-10px); /* Move the project name to the left */
  color: #a0a0a0;
}

.project:hover .project-category {
  transform: translateX(10px); /* Move the project category to the right */
  color: #a0a0a0;
}

.project-name {
  font-size: 3rem;
  color: #333333;
  margin: 0;
  padding: 0;
}

.project-category {
  font-size: 1rem;
  color: #666666;
  margin: 0;
  padding: 0;
}

.project-divider {
  border: none;
  border-top: 1px solid #c8c8c8;
  margin: 0;
}

/* Popup styling */
.project-popup {
  position: fixed;
  width: 300px;
  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 10;
  text-align: center;
  border-radius: 8px;
  pointer-events: none;
  transform: scale(0);
  transition: transform 0.25s ease, opacity 0.25s ease, top 0.15s ease, left 0.15s ease;
  will-change: transform, opacity;
}

.image-wrapper {
  height: 100%; 
  overflow: hidden; 
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* Vertical direction */
  transition: transform 0.5s ease-in-out;
}

.project-popup img {
  width: 100%;
  height: auto;
  object-fit: cover; 
  border-radius: 4px;
  display: block;
}

.view-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 70px;
  height: 70px;
  background-color: #50c878;
  color: white;
  border-radius: 50%;
  line-height: 70px;
  text-align: center;
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
}

.projects-end-line {
  border: none;
  border-top: 1px solid #c8c8c8;
  margin: 0;
}

.more-projects-button-link {
  display: block;
  text-decoration: none;
  width: max-content;
  margin: 40px auto;
}

.more-projects-button {
  display: block;
  margin: 0 auto;
  padding: 20px 50px; /* Increased padding for larger button */
  font-size: 1.2rem; /* Slightly larger font size */
  color: #fff;
  background-color: #333333;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: transform 0.15s ease; /* Removed background-color transition */
}

.more-projects-button::before {
  content: '';
  position: absolute;
  bottom: -50%; /* Start below the button */
  left: 50%;
  width: 150%;
  height: 160%;
  background-color: #50c878; /* Hover color */
  border-radius: 50%; /* Ensure the curved effect */
  transform: translate(-50%, 100%) scale(1, 0.3); /* Initial curved position */
  transition: transform 0.3s ease-out; /* Make the animation start immediately with ease-out */
  z-index: 0;
}

.more-projects-button:hover::before {
  transform: translate(-50%, 0) scale(1, 1); /* Animate to cover the button */
}

.more-projects-button:hover .button-text {
  color: white; /* Keep text white on hover */
}

.button-text {
  position: relative;
  z-index: 1;
  color: white;
}

/* Responsive styles for tablet and mobile */
@media (max-width: 768px) {

  .projects-section {
    padding: 5% 5%;
  }

  .project-info {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 5%;
  }

  .project-name {
    font-size: 2rem;
  }

  .project-category {
    font-size: 0.9rem;
    margin-top: 10px;
  }

  .project {
    padding: 10px 0;
  }

  .view-button {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  .more-projects-button {
    width: 100%; /* Ensure the button takes up the full width */
    max-width: 350px; /* Set a max-width to control button size */
    padding: 20px 40px; /* Increased padding for larger button */
    font-size: 1rem;
    text-align: center; /* Center the text */
  }

  .project-popup {
    width: 90%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .projects-title {
    font-size: 0.9rem;
  }

  .project-info {
    padding: 20px 5%;
  }

  .project-name {
    font-size: 1.5rem;
  }

  .project-category {
    font-size: 0.8rem;
  }

  .view-button {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1rem;
  }

  .more-projects-button {
    width: 100%; /* Ensure the button takes up the full width */
    max-width: 350px; /* Set a max-width to control button size */
    padding: 15px 25px; /* Reduced padding for smaller button */
    font-size: 0.9rem; /* Slightly smaller font size for mobile */
    text-align: center; /* Center the text */
  }
}
</style>

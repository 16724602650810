<!-- src/components/home/AboutSection.vue -->
<template>
  <div class="about-section" ref="aboutSection">
    <div class="about-left">
      <p class="about-text">
        <!-- Iterate over each word for animation -->
        <span
          v-for="(word, index) in aboutTextWords"
          :key="index"
          class="text-word"
          :class="{ 'animate-word': textVisible }"
          :style="{ animationDelay: `${index * 0.05}s` }"
        >
          {{ word }}
        </span>
      </p>
    </div>
    <div class="about-right">
      <p class="about-subtext">
        The combination of my passion for design, code & interaction positions me in a unique place in the web design world.
      </p>
      <!-- Use router-link to create a navigable link -->
      <router-link to="/about" class="circle-link">
        <div
          class="magnetic-circle"
          @mousemove="handleMouseMove"
          @mouseleave="handleMouseLeave"
          ref="circle"
        >
          <span class="circle-text">About me</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'

// Text for the left part
const aboutText = ref(
  "Helping brands to stand out in the digital era. Together we will set the new status quo. No nonsense always on the cutting edge."
)

// Split the text into words for animation
const aboutTextWords = computed(() => aboutText.value.split(' '))

// References to DOM elements
const aboutSection = ref(null)
const circle = ref(null)
const textVisible = ref(false) // Track visibility of the text

// Function to handle scroll animation for the left text
const handleScroll = () => {
  const aboutElement = aboutSection.value
  const bounding = aboutElement.getBoundingClientRect()

  // Check if the section is fully visible in the viewport
  if (bounding.top < window.innerHeight * 0.75 && bounding.bottom > 0) {
    textVisible.value = true // Start the text animation
  } else {
    textVisible.value = false // Reset the text animation (optional)
  }
}

// Function to handle magnetic effect for the circle
const handleMouseMove = (event) => {
  const circleElement = circle.value
  const bounding = circleElement.getBoundingClientRect()
  const centerX = bounding.left + bounding.width / 2
  const centerY = bounding.top + bounding.height / 2
  const deltaX = event.clientX - centerX
  const deltaY = event.clientY - centerY
  const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2)

  if (distance < 150) { // Magnetic effect threshold
    const strength = 20 / distance
    circleElement.style.transform = `translate(${deltaX * strength}px, ${deltaY * strength}px)`
  } else {
    circleElement.style.transform = 'translate(0, 0)'
  }
}

// Function to reset circle position on mouse leave
const handleMouseLeave = () => {
  const circleElement = circle.value
  circleElement.style.transform = 'translate(0, 0)'
}

// Setup scroll listener
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  // Initial check in case the section is already in view on page load
  handleScroll()
})

// Cleanup scroll listener
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style scoped>
.about-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top */
  max-width: 1200px;
  margin: 0 auto;
  padding: 10% 20px 0 20px;
  box-sizing: border-box;
}

.about-left {
  width: 70%; /* Make the left part wider */
  overflow: hidden; /* Hide overflow to create the sliding effect */
}

.about-text {
  font-size: 2.5rem; /* Increase font size for larger text */
  color: #333333;
  text-align: left; /* Align text to the left */
  margin: 0; /* Ensure no extra margin is added */
  line-height: 1.5; /* Add line height for readability */
}

.text-word {
  display: inline-block;
  opacity: 0;
  margin-right: 0.5rem; /* Space between words */
}

.animate-word {
  animation: fadeIn 0.3s forwards; /* Faster animation duration */
  animation-timing-function: ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px); /* Optional small movement */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.about-right {
  width: 25%; /* Make the right part smaller */
  text-align: left; /* Align text to the left */
}

.about-subtext {
  font-size: 1.2rem;
  margin-bottom: 20px;
  margin-top: 0; /* Ensure no extra margin at the top */
  line-height: 1.6; /* Add line height for readability */
}

.circle-link {
  text-decoration: none; /* Remove underline */
  display: block; /* Ensure it behaves like a block element */
}

.magnetic-circle {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #333333; /* Circle color */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: transform 0.6s ease-out; /* Smoother transition */
  cursor: pointer; /* Indicate interactiveness */
}

.circle-text {
  z-index: 1;
  font-size: 1.5rem;
  color: white; /* "About me" text color */
  position: relative;
}

.magnetic-circle::before {
  content: '';
  position: absolute;
  bottom: -50%; /* Start below the circle */
  left: 50%;
  width: 150%;
  height: 150%;
  background-color: #50c878; /* Hover color */
  border-radius: 50%;
  transform: translate(-50%, 100%) scale(1, 0.3); /* Initial curved position */
  transition: transform 0.4s ease-out; /* Make the animation start immediately with ease-out */
  z-index: 0;
}

.magnetic-circle:hover::before {
  transform: translate(-50%, 0) scale(1, 1); /* Animate to cover the circle */
}

.magnetic-circle:hover .circle-text {
  color: white; /* Keep text white on hover */
}

/* Unified Tablet and Mobile Styles */
@media (max-width: 1024px) {
  .about-section {
    padding: 5% 20px; /* Adjust padding for both tablet and mobile */
    flex-direction: column; /* Stack sections vertically */
  }

  .about-left {
    width: 100%; /* Full width for left section */
    text-align: center; /* Center-align text */
    margin-bottom: 5px; /* Add space below the left section */
  }

  .about-text {
    font-size: 2rem; /* Adjust font size */
    line-height: 1.4; /* Adjust line height */
  }

  .about-right {
    display: flex;
    justify-content: space-between; /* Align text on left and circle on right */
    align-items: center;
    width: 100%; /* Full width for the right section */
    padding: 20px 0; /* Add some padding */
  }

  .about-subtext {
    font-size: 1rem; /* Adjust font size */
    text-align: left; /* Align text to the left */
    margin: 0; /* Remove margins */
    flex: 1; /* Allow text to take up available space */
    padding-right: 10px; /* Add space between text and circle */
  }

  .magnetic-circle {
    width: 120px; /* Maintain circle size */
    height: 120px; /* Ensure height matches width to keep it circular */
    flex-shrink: 0; /* Prevent the circle from shrinking */
    margin: 0; /* Remove margin */
  }

  .circle-text {
    font-size: 1rem; /* Adjust text size */
  }
}

/* Small Mobile Styles */
@media (max-width: 480px) {
  .about-section {
    padding: 5% 20px; /* Narrower padding */
  }

  .about-text {
    font-size: 1.5rem; /* Smaller font size */
  }

  .about-subtext {
    font-size: 0.8rem; /* Further smaller font for small mobile */
    padding-right: 10px; /* Add space between text and circle */
  }

  .magnetic-circle {
    width: 100px; /* Smaller circle size */
    height: 100px; /* Ensure height matches width to keep it circular */
    flex-shrink: 0; /* Prevent the circle from shrinking */
  }

  .circle-text {
    font-size: 0.9rem; /* Adjust text size */
  }
}

</style>

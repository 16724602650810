<!-- src/components/Menu.vue -->
<template>
  <div>
    <nav :class="{ scrolled: isScrolled, home: isHomePage }">
      <div
        class="logo"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeaveLogo"
        @mousemove="handleLogoMouseMove"
      >
        <router-link to="/" class="logo-text">
          <span class="text1" :class="{ hidden: isHovered, visible: !isHovered }"
            >INFINITY-LINK</span
          >
          <span class="text2" :class="{ visible: isHovered, hidden: !isHovered }"
            >&copy; Coded by Sario</span
          >
        </router-link>
      </div>
      <ul v-if="!isScrolled && !isMobile">
        <li v-for="(item, index) in menuItems" :key="index">
          <!-- Check if the item has a 'path' -->
          <template v-if="item.path">
            <router-link
              :to="item.path"
              exact-active-class="router-link-exact-active"
              @mousemove="handleMouseMove"
              @mouseleave="handleMouseLeave"
            >
              {{ item.name }}
            </router-link>
          </template>
          <!-- If the item has an 'action' -->
          <template v-else-if="item.action">
            <a
              href="#"
              @click.prevent="performAction(item.action)"
              @mousemove="handleMouseMove"
              @mouseleave="handleMouseLeave"
            >
              {{ item.name }}
            </a>
          </template>
        </li>
      </ul>
    </nav>
    <div
      class="menu-icon-container"
      :class="{ visible: showPopupMenu || isMobile }"
      @click="toggleSideMenu"
      @mouseenter="disableScroll"
      @mouseleave="enableScroll"
      @mousemove="handleIconMouseMove"
    >
      <div
        class="menu-icon"
        ref="menuIcon"
        :class="{ open: isSideMenuOpen }"
      >
        {{ isSideMenuOpen ? '✕' : '☰' }}
      </div>
    </div>
    <div :class="{ overlay: true, active: isSideMenuOpen }"></div>
    <div
      :class="{ 'side-menu': true, open: isSideMenuOpen }"
      @wheel.prevent
      @touchmove.prevent
    >
      <div class="side-menu-header">
        <h2>NAVIGATION</h2>
        <div class="line"></div>
      </div>
      <ul>
        <li v-for="(item, index) in menuItems" :key="index">
          <!-- For items with a 'path' -->
          <template v-if="item.path">
            <router-link
              :to="item.path"
              @click="toggleSideMenu"
              exact-active-class="router-link-exact-active"
            >
              <span
                class="side-menu-item"
                @mousemove="handleMouseMoveSideMenu"
                @mouseleave="handleMouseLeaveSideMenu"
              >
                <span
                  class="dot"
                  :class="{ active: $route.path === item.path }"
                ></span>
                <span class="side-menu-text">{{ item.name }}</span>
              </span>
            </router-link>
          </template>
          <!-- For items with an 'action' -->
          <template v-else-if="item.action">
            <a
              href="#"
              @click.prevent="performAction(item.action); toggleSideMenu()"
            >
              <span
                class="side-menu-item"
                @mousemove="handleMouseMoveSideMenu"
                @mouseleave="handleMouseLeaveSideMenu"
              >
                <span class="dot"></span>
                <span class="side-menu-text">{{ item.name }}</span>
              </span>
            </a>
          </template>
        </li>
      </ul>
    </div>
    <div
      v-if="isSideMenuOpen"
      class="scroll-overlay"
      @wheel.prevent
      @touchmove.prevent
    ></div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'

const isScrolled = ref(false)
const isHovered = ref(false)
const isIconHovered = ref(false)
const isSideMenuOpen = ref(false)
const isMobile = ref(window.innerWidth <= 768)
const menuIcon = ref(null)

const route = useRoute()

const menuItems = [
  { name: 'Home', path: '/' },
  { name: 'About', path: '/about' },
  { name: 'Portfolio', path: '/portfolio' },
  { name: 'Contact', action: 'scrollToBottom' } // Updated item with 'action'
]

const handleScroll = () => {
  const scrollPosition = window.scrollY
  isScrolled.value = scrollPosition > 120
}

const showPopupMenu = computed(() => {
  return isScrolled.value && !isIconHovered.value
})

const toggleSideMenu = () => {
  isSideMenuOpen.value = !isSideMenuOpen.value
  if (isSideMenuOpen.value) {
    disableScroll()
  } else {
    enableScroll()
  }
}

// Function to perform actions like scrolling to the bottom
const performAction = (action) => {
  if (action === 'scrollToBottom') {
    const scrollPosition = window.scrollY + window.innerHeight;
    const bottomPosition = document.body.scrollHeight;

    if (Math.abs(scrollPosition - bottomPosition) < 1) {
      // User is at the bottom, perform a smooth up and down scroll
      const amplitude = 50; // Maximum scroll distance (in pixels)
      const duration = 600; // Duration of the animation (in milliseconds)
      const startScroll = window.scrollY;
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = elapsed / duration;

        if (progress < 1) {
          // Smoothly move up and down using a sine wave
          const displacement = amplitude * Math.sin(progress * Math.PI);
          window.scrollTo(0, startScroll - displacement);
          requestAnimationFrame(animate);
        } else {
          // Ensure we end up back at the starting position
          window.scrollTo(0, startScroll);
        }
      };

      requestAnimationFrame(animate);
    } else {
      // Scroll to the bottom
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }
  }
};

const handleMouseMove = (event) => {
  const link = event.target
  const rect = link.getBoundingClientRect()
  const offsetX = event.clientX - rect.left - rect.width / 2
  const offsetY = event.clientY - rect.top - rect.height / 2
  const maxOffset = 10

  link.style.transform = `translate(${Math.min(
    Math.max(offsetX, -maxOffset),
    maxOffset
  )}px, ${Math.min(Math.max(offsetY, -maxOffset), maxOffset)}px)`
}

const handleMouseLeave = (event) => {
  const link = event.target
  link.style.transform = `translate(0, 0)`
}

const handleMouseMoveSideMenu = (event) => {
  const item = event.currentTarget
  if (item) {
    const rect = item.getBoundingClientRect()
    const offsetX = event.clientX - rect.left - rect.width / 2
    const offsetY = event.clientY - rect.top - rect.height / 2
    const maxOffset = 10

    item.style.transform = `translate(${Math.min(
      Math.max(offsetX, -maxOffset),
      maxOffset
    )}px, ${Math.min(Math.max(offsetY, -maxOffset), maxOffset)}px)`
  }
}

const handleMouseLeaveSideMenu = (event) => {
  const item = event.currentTarget
  if (item) {
    item.style.transform = `translate(0, 0)`
  }
}

const handleMouseEnter = () => {
  isHovered.value = true
}

const handleMouseLeaveLogo = () => {
  isHovered.value = false
  const logoText = document.querySelector('.logo-text')
  logoText.style.transform = 'translate(0, 0)'
}

const handleLogoMouseMove = (event) => {
  const logoText = event.currentTarget.querySelector('.logo-text')
  const rect = event.currentTarget.getBoundingClientRect()
  const offsetX = event.clientX - rect.left - rect.width / 2
  const offsetY = event.clientY - rect.top - rect.height / 2
  const maxOffset = 10

  logoText.style.transform = `translate(${Math.min(
    Math.max(offsetX, -maxOffset),
    maxOffset
  )}px, ${Math.min(Math.max(offsetY, -maxOffset), maxOffset)}px)`
}

const handleIconMouseMove = (event) => {
  const iconContainer = event.currentTarget
  const rect = iconContainer.getBoundingClientRect()
  const offsetX = event.clientX - rect.left - rect.width / 2
  const offsetY = event.clientY - rect.top - rect.height / 2
  const maxOffset = 5

  menuIcon.value.style.transform = `translate(${Math.min(
    Math.max(offsetX, -maxOffset),
    maxOffset
  )}px, ${Math.min(Math.max(offsetY, -maxOffset), maxOffset)}px)`
}

const disableScroll = () => {
  window.addEventListener('wheel', preventScroll, { passive: false })
  window.addEventListener('touchmove', preventScroll, { passive: false })
}

const enableScroll = () => {
  window.removeEventListener('wheel', preventScroll, { passive: false })
  window.removeEventListener('touchmove', preventScroll, { passive: false })
}

const preventScroll = (event) => {
  event.preventDefault()
}

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  window.addEventListener('resize', updateIsMobile)
  updateIsMobile()
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  window.removeEventListener('resize', updateIsMobile)
})

// Check if the current page is the home page
const isHomePage = computed(() => route.path === '/')
</script>

<style scoped>
nav {
  position: absolute;
  right: 0;
  left: 0;
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  z-index: 1000;
}

nav.home {
  background-color: transparent;
  color: white;
}

/* Logo Styles */
.logo {
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 260px;
  height: 50px;
  display: flex;
  align-items: center;
}

.logo-text {
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: transform 0.5s ease-out;
  color: inherit;
}

.text1,
.text2 {
  position: absolute;
  transition: transform 0.8s ease, opacity 0.8s ease;
  white-space: nowrap;
  padding: 0 10px;
  box-sizing: border-box;
  opacity: 1;
}

.text1.hidden,
.text2.hidden {
  opacity: 0;
}

.text1.hidden {
  transform: translateX(-100%);
}

.text1.visible {
  transform: translateX(0);
}

.text2.hidden {
  transform: translateX(100%);
}

.text2.visible {
  transform: translateX(0);
}

/* Main Menu Styles */
nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 30px;
}

nav ul li {
  margin: 0;
}

nav ul li a,
nav ul li router-link {
  text-decoration: none;
  position: relative;
  padding: 5px 0;
  display: inline-block;
  transition: transform 0.5s ease-out;
  color: inherit;
}

nav ul li a::after,
nav ul li router-link::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  background: currentColor;
  border-radius: 50%;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%) scale(0);
  transition: transform 0.3s ease;
}

nav ul li a:hover::after,
nav ul li a.router-link-exact-active::after,
nav ul li router-link:hover::after,
nav ul li router-link.router-link-exact-active::after {
  transform: translateX(-50%) scale(1);
}

/* Menu Icon Styles */
.menu-icon-container {
  position: fixed;
  top: 45px;
  right: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  overflow: visible;
  z-index: 1003;
  transform: scale(0);
  transition: transform 0.3s ease, transform 0.3s ease-out;
}

.menu-icon-container.visible {
  transform: scale(1);
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
  background-color: #2e2e2e;
  color: white;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease-out, background 0.5s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.menu-icon::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #50c878;
  clip-path: path('M 0 100 Q 50 125 100 100 V 100 H 0 Z');
  transform: translateY(100%);
  transition: transform 0.5s, clip-path 0.5s;
  z-index: -1;
}

.menu-icon:hover::before,
.menu-icon.open::before {
  transform: translateY(0);
  clip-path: path('M 0 0 Q 50 -25 100 0 V 100 H 0 Z');
}

/* Side Menu Styles */
.side-menu {
  position: fixed;
  top: 0;
  right: -33.33%;
  width: 33.33%;
  height: 100%;
  background-color: #2e2e2e;
  color: white;
  z-index: 1002;
  transition: right 0.6s ease, border-radius 0.6s ease;
  padding-top: 85px;
  border-top-left-radius: 100px 50%;
  border-bottom-left-radius: 100px 50%;
}

.side-menu.open {
  right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.side-menu-header {
  padding: 0 80px;
}

.side-menu-header h2 {
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  color: #d3d3d3;
  text-align: left;
}

.side-menu-header .line {
  width: 220px;
  height: 0.5px;
  background-color: #d3d3d3;
  margin: 40px 0 30px;
}

.side-menu ul {
  list-style-type: none;
  padding: 0;
}

.side-menu ul li {
  padding: 20px 80px;
  text-align: left;
  position: relative;
}

.side-menu ul li .dot {
  position: absolute;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  left: 0;
  top: 50%;
  margin-left: -30px;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease;
}

.side-menu ul li:hover .dot,
.side-menu ul li .router-link-exact-active .dot {
  transform: translateY(-50%) scale(1);
}

.side-menu ul li .side-menu-item {
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: transform 0.5s ease-out;
}

.side-menu ul li .side-menu-text {
  color: white;
  text-decoration: none;
  font-size: 45px;
  display: inline-block;
  transition: transform 0.5s ease-out;
}

/* Overlay Styles */
.scroll-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333333;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.overlay.active {
  opacity: 0.6;
  visibility: visible;
}

@media (max-width: 1080px) {
  .side-menu {
    right: -45%;
    width: 45%;
  }
}

/* Responsive Styles */
@media (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
}

@media (max-width: 768px) {
  .logo {
    font-size: 20px;
    width: 200px;
  }

  .logo-text {
    font-size: 20px;
  }

  .menu-icon-container {
    top: 15px;
    right: 15px;
    width: 60px;
    height: 60px;
  }

  .menu-icon {
    font-size: 22px;
    width: 60px;
    height: 60px;
  }

  .side-menu {
    right: -100%;
    width: 100%;
    padding-top: 60px;
  }

  .side-menu.open {
    right: 0;
  }

  .side-menu ul li {
    padding: 20px 80px;
    text-align: left;
  }

}

@media (max-width: 480px) {
  nav {
    padding: 15px 0;
  }
}

</style>

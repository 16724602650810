<!-- src/App.vue -->
<template>
  <div id="app">
    <Preloader v-if="showPreloader" @finished="onPreloaderFinished" />
    <PagePreloader v-if="showPagePreloader" />
    <div :class="{ 'content-visible': !showPreloader && !showPagePreloader, 'content-behind': showPreloader || showPagePreloader }">
      <!-- Apply a class to the Menu based on the route -->
      <Menu :class="{ 'menu-error-page': $route.name === 'Not Found' }" />
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
import Menu from './components/Menu.vue'
import Preloader from './components/Preloader.vue'
import PagePreloader from './components/PagePreloader.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const showPreloader = ref(true)
const showPagePreloader = ref(false)

const onPreloaderFinished = () => {
  showPreloader.value = false
}

const router = useRouter()

router.beforeEach((to, from, next) => {
  if (!showPreloader.value) {
    showPagePreloader.value = true
    next()
  } else {
    next()
  }
})

router.afterEach(() => {
  if (!showPreloader.value) {
    setTimeout(() => {
      showPagePreloader.value = false
    }, 2000) // Adjusted timing for a smoother transition
  }
})
</script>

<style>
@import './assets/style.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.content-behind {
  position: relative;
  z-index: 1;
}

.content-visible {
  position: relative;
  z-index: 2;
}

/* Add a specific class for the error page */
.menu-error-page {
  color: white; /* Change the menu text to white on the 404 page */
}
</style>
